import * as React from "react"
import styled from "@emotion/styled"
import { Drawer, IconButton } from "@mui/material"
import SegmentIcon from "@mui/icons-material/Segment"
import { StaticImage } from "gatsby-plugin-image"
import { Link, navigate } from "gatsby"
import { Divider } from "@mui/material"
import { Typography } from "@mui/material"
import { Color } from "../../util/Color"

const MobileHeader = () => {
  const [open, setOpen] = React.useState(false)

  return (
    <Root>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={() => setOpen(true)}
        sx={{
          color: "white",
        }}
      >
        <SegmentIcon
          sx={{
            width: "40px",
            height: "40px",
          }}
        />
      </IconButton>

      <Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
        <DrawerRoot>
          <LogoWrapper onClick={() => navigate("/")}>
            <StaticImage src="./header-logo.svg" alt="logo" />
          </LogoWrapper>
          <SDivider />
          <LinkArea to="/about">
            <STypography variant="h5">会社について</STypography>
          </LinkArea>
          <SDivider />
          <LinkArea to="/service">
            <STypography variant="h5">事業内容</STypography>
          </LinkArea>
          <SDivider />
          <LinkArea to="https://www.juggle.jp/">
            <STypography variant="h5">JUGGLE</STypography>
          </LinkArea>
          <SDivider />
          <LinkArea to="https://lp.p-coll.com/">
            <STypography variant="h5">ピースコレクション</STypography>
          </LinkArea>
          <SDivider />
          <LinkArea to="/news">
            <STypography variant="h5">お知らせ</STypography>
          </LinkArea>
          <SDivider />
          <LinkArea to="https://qiita.com/organizations/opening-line">
            <STypography variant="h5">BLOG</STypography>
          </LinkArea>
          <SDivider />
          <LinkArea to="/contact">
            <STypography variant="h5">お問い合わせ</STypography>
          </LinkArea>
        </DrawerRoot>
      </Drawer>
    </Root>
  )
}

export default MobileHeader

const Root = styled("div")({
  position: "fixed",
  right: "16px",
  top: "16px",
  zIndex: "999",
})

const DrawerRoot = styled("div")({
  width: "280px",
  paddingTop: "28px",
  paddingLeft: "28px",
})

const LogoWrapper = styled("div")(({ view }) => ({
  height: "20px",
  width: "138px",
  marginBottom: "30px",
}))

const SDivider = styled(Divider)({
  width: "252px",
  height: "1px",
  border: "none",
  background: Color.logo_orange,
})

const STypography = styled(Typography)(({ view }) => ({
  fontFamily: "Noto Sans JP",
  fontWeight: 700,
  fontSize: "12px",
  margin: "18px 0px",
  marginLeft: "10px",
}))

const LinkArea = styled(Link)({
  textDecoration: "none",
})
